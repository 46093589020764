<template>
  <div v-if="upsellGroup">
    <context-title :title="groupName" :passedActions="primaryTitleBarActions">
      <template slot="secondary">
        <div class="row no-gutters align-items-center">
          <div class="platform-toggle col-12">
            <toggle v-model="groupEnabled" slider="true" label="Enabled" />
          </div>
        </div>
        <div class="d-flex" style="margin-left: auto;">
          <text-button
            class="block-xs--sm-left"
            style="margin-right: 20px;"
            type="delete"
            @click.native="() => deleteConfirmationModal = true"
          >Delete Upsell Group</text-button>
          <ActionButton
            v-if="hasPreviousItem()"
            type="faded"
            :noIcon="true"
            :action="gotoPreviousItem"
          >View Previous</ActionButton>
          <ActionButton type="faded" :noIcon="true" :action="leave">Close</ActionButton>

          <ActionButton
            v-if="hasNextItem()"
            type="faded"
            :noIcon="true"
            :action="gotoNextItem"
          >View Next</ActionButton>
        </div>
      </template>
    </context-title>
    <LineItem class="row no-gutters">
      <div class="col-12">
        <FormInput
          v-model="groupName"
          class="large block-xs--xs"
          label="Group Name"
          spellcheck="true"
          required="true"
          labelSize="partner-form"
        />
      </div>
    </LineItem>
    <LineItem class="row no-gutters">
      <div class="col-12">
        <div class="label block-xs--xs">Items:</div>
      </div>
    </LineItem>
    <v-container class="fill-height pa-0" fluid>
      <v-row  >
        <EditCard :group="upsellGroup" @onChange="handleChange" />
      </v-row>
    </v-container>
    <EditingModal v-if="navigationConfirmationModal" size="sm">
      <AlertDialog
        title="Unsaved Changes"
        :onExit="() => navigationConfirmationModal = false"
        :actions="navigationConfirmationActions"
      >You have unsaved changes, would you like to save these changes?</AlertDialog>
    </EditingModal>
    <EditingModal v-if="deleteConfirmationModal" size="sm">
      <AlertDialog
        title="Confirm Delete"
        :onExit="() => deleteConfirmationModal = false"
        :actions="deleteConfirmationActions"
      >{{ `Are you sure you want to delete ${groupName}?` }}</AlertDialog>
    </EditingModal>
  </div>
</template>

<script>
import ContextTitle from "components/context_title.vue";
import EditCard from "components/upsell/upsell_group_edit_card.vue";
import ActionButton from "components/action_button";
import TextButton from "components/text_button.vue";
import EditingModal from "components/editing_modal.vue";
import AlertDialog from "components/alert_dialog.vue";
import Toggle from "components/cleverly/Toggle.vue";
import FormInput from "components/cleverly/FormInput.vue";
import LineItem from "components/line_item.vue";

import { mapActions, mapGetters } from "vuex";

import { UpsellDataHelper } from "mixins/upsell.js";
import clone from "clone";

export default {
    name: "UpsellGroupEdit",
    data() {
        return {
            dirtyUpsellGroup: undefined,
            deleteConfirmationModal: false,
            deleteConfirmationActions: [
                {
                    type: "cancel",
                    display: "Cancel",
                    run: () => (this.deleteConfirmationModal = false)
                },
                {
                    type: "remove-red",
                    display: "Yes, delete",
                    run: this.deleteGroup
                }
            ],
            pendingRoute: undefined,
            navigationConfirmationModal: false,
            navigationConfirmationActions: [
                {
                    type: "remove-red",
                    display: "No",
                    run: this.confirmNavigation
                },
                {
                    type: "proceed-green",
                    display: "Yes",
                    run: () => {
                        this.save().then(() => this.leave());
                    }
                }
            ]
        };
    },
    components: {
        ContextTitle,
        EditCard,
        ActionButton,
        TextButton,
        AlertDialog,
        EditingModal,
        Toggle,
        FormInput,
        LineItem
    },
    mixins: [UpsellDataHelper],
    computed: {
        primaryTitleBarActions() {
            if (!this.dirty) return [];

            const items = [];

            const previous = {
                type: "proceed-green",
                display: "Save & View Previous",
                run: () => {
                    this.save().then(() => this.gotoPreviousItem());
                }
            };
            const saveClose = {
                type: "proceed-green",
                display: "Save & Close",
                run: () => {
                    this.save().then(() => this.leave());
                }
            };

            const saveNext = {
                type: "next-green",
                display: "Save & View Next",
                run: () => {
                    this.save().then(() => {
                        this.gotoNextItem();
                    });
                }
            };

            items.push(saveClose);

            if (this.hasNextItem(this.productId)) {
                items.push(saveNext);
            }

            return items;
        },
        upsellGroup() {
            let upsellGroup = this.currentUpsellGroups.find(
                el => el.GroupID == this.$route.params.GroupID
            );

            return upsellGroup;
        },
        groupIndex() {
            if (!this.currentUpsellGroups.length) return false;
            return this.currentUpsellGroups.findIndex(
                el => el.GroupID == this.$route.params.GroupID
            );
        },
        dirty() {
            return this.dirtyUpsellGroup != undefined;
        },
        groupName: {
            get() {
                if (this.dirtyUpsellGroup != undefined)
                    return this.dirtyUpsellGroup.GroupName;
                return this.upsellGroup.GroupName;
            },
            set(newValue) {
                if (this.dirtyUpsellGroup == undefined) {
                    this.dirtyUpsellGroup = clone(this.upsellGroup);
                }
                this.dirtyUpsellGroup.GroupName = newValue;
            }
        },
        groupEnabled: {
            get() {
                if (this.dirtyUpsellGroup != undefined)
                    return this.dirtyUpsellGroup.Enabled;
                return this.upsellGroup.Enabled;
            },
            set(newValue) {
                if (this.dirtyUpsellGroup == undefined) {
                    this.dirtyUpsellGroup = clone(this.upsellGroup);
                }
                this.dirtyUpsellGroup.Enabled = newValue;
            }
        }
    },
    methods: {
        deleteGroup() {
            this.deleteConfirmationModal = false;
            let waypointID = this.$route.params.store;
            return this.deleteUpsellGroup({
                waypointID,
                groupID: this.upsellGroup.GroupID
            }).then(() => {
                this.dirtyUpsellGroup = undefined;
                this.leave();
            });
        },
        gotoNextItem() {
            if (!this.hasNextItem) return;
            let nextTargetID = this.currentUpsellGroups[this.groupIndex + 1].GroupID;
            this.guardRouteAway({
                name: "upsell-group-edit",
                params: {
                    GroupID: nextTargetID
                }
            });
        },
        gotoPreviousItem() {
            if (!this.hasPreviousItem) return;
            let nextTargetID = this.currentUpsellGroups[this.groupIndex - 1].GroupID;
            this.guardRouteAway({
                name: "upsell-group-edit",
                params: {
                    GroupID: nextTargetID
                }
            });
        },
        leave() {
            this.guardRouteAway({
                name: "upsell-group"
            });
        },
        hasNextItem() {
            if (
                this.groupIndex == -1 ||
        this.groupIndex + 1 >= this.currentUpsellGroups.length
            )
                return false;
            return true;
        },
        hasPreviousItem() {
            if (this.groupIndex == -1 || this.groupIndex == 0) return false;
            return true;
        },
        guardRouteAway(route) {
            if (this.dirtyUpsellGroup === undefined) {
                this.$router.push(route);
                return;
            }

            this.pendingRoute = route;
            this.navigationConfirmationModal = true;
        },
        confirmNavigation() {
            this.navigationConfirmationModal = false;
            this.$router.push(this.pendingRoute);
            this.pendingRoute = undefined;
        },
        save() {
            if (!this.validateForm()) return Promise.Reject();
            let waypointID = this.$route.params.store;
            return this.updateUpsellGroup({
                waypointID,
                upsellGroup: this.dirtyUpsellGroup
            }).then(() => {
                this.dirtyUpsellGroup = undefined;
                return Promise.resolve(true);
            });
        },
        validateForm() {
            if (this.dirtyUpsellGroup === undefined) return false;
            if (this.dirtyUpsellGroup.GroupName == "") {
                this.toast("Group name is required.", "error");
                return false;
            } else if (this.dirtyUpsellGroup.items.length == 0) {
                this.toast("At least 1 upsell item is required.", "error");
                return false;
            }
            return true;
        },
        rebuildGroups() {
            if (this.upsellGroup == undefined) return;
            this.dirtyUpsellGroup = undefined;
        },
        handleChange(items) {
            if (this.dirtyUpsellGroup === undefined) {
                this.dirtyUpsellGroup = clone(this.upsellGroup);
            }
            this.dirtyUpsellGroup = {
                ...this.dirtyUpsellGroup,
                items: items
            };
        },
        ...mapActions("UpsellStore", ["updateUpsellGroup", "deleteUpsellGroup"])
    },
    watch: {
        upsellGroup(newValue, oldValue) {
            this.rebuildGroups();
        }
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";

input {
  @include below(xs) {
    width: 100%;
  }
}

.form-input {
  input {
    @include above(md) {
      width: 331px;
    }
  }
}

.block-label > label {
  @include roboto-bold;
  display: block;
  font-size: fz(sm);
  color: $grey_mid_1;
  margin: spacing(xs) 0px;
  width: auto;
}

.line-item {
  padding: 0px 30px;
  margin-right: 0;
}

.label-offset {
  margin-left: 155px;
  &.line-item {
    margin-right: -30px;
    &:before {
      display: none;
    }
  }
}
</style>

