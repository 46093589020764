var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.upsellGroup
    ? _c(
        "div",
        [
          _c(
            "context-title",
            {
              attrs: {
                title: _vm.groupName,
                passedActions: _vm.primaryTitleBarActions,
              },
            },
            [
              _c("template", { slot: "secondary" }, [
                _c(
                  "div",
                  { staticClass: "row no-gutters align-items-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "platform-toggle col-12" },
                      [
                        _c("toggle", {
                          attrs: { slider: "true", label: "Enabled" },
                          model: {
                            value: _vm.groupEnabled,
                            callback: function ($$v) {
                              _vm.groupEnabled = $$v
                            },
                            expression: "groupEnabled",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "d-flex",
                    staticStyle: { "margin-left": "auto" },
                  },
                  [
                    _c(
                      "text-button",
                      {
                        staticClass: "block-xs--sm-left",
                        staticStyle: { "margin-right": "20px" },
                        attrs: { type: "delete" },
                        nativeOn: {
                          click: function ($event) {
                            return (() =>
                              (_vm.deleteConfirmationModal = true)).apply(
                              null,
                              arguments
                            )
                          },
                        },
                      },
                      [_vm._v("Delete Upsell Group")]
                    ),
                    _vm.hasPreviousItem()
                      ? _c(
                          "ActionButton",
                          {
                            attrs: {
                              type: "faded",
                              noIcon: true,
                              action: _vm.gotoPreviousItem,
                            },
                          },
                          [_vm._v("View Previous")]
                        )
                      : _vm._e(),
                    _c(
                      "ActionButton",
                      {
                        attrs: {
                          type: "faded",
                          noIcon: true,
                          action: _vm.leave,
                        },
                      },
                      [_vm._v("Close")]
                    ),
                    _vm.hasNextItem()
                      ? _c(
                          "ActionButton",
                          {
                            attrs: {
                              type: "faded",
                              noIcon: true,
                              action: _vm.gotoNextItem,
                            },
                          },
                          [_vm._v("View Next")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("LineItem", { staticClass: "row no-gutters" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c("FormInput", {
                  staticClass: "large block-xs--xs",
                  attrs: {
                    label: "Group Name",
                    spellcheck: "true",
                    required: "true",
                    labelSize: "partner-form",
                  },
                  model: {
                    value: _vm.groupName,
                    callback: function ($$v) {
                      _vm.groupName = $$v
                    },
                    expression: "groupName",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("LineItem", { staticClass: "row no-gutters" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("div", { staticClass: "label block-xs--xs" }, [
                _vm._v("Items:"),
              ]),
            ]),
          ]),
          _c(
            "v-container",
            { staticClass: "fill-height pa-0", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                [
                  _c("EditCard", {
                    attrs: { group: _vm.upsellGroup },
                    on: { onChange: _vm.handleChange },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm.navigationConfirmationModal
            ? _c(
                "EditingModal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "AlertDialog",
                    {
                      attrs: {
                        title: "Unsaved Changes",
                        onExit: () => (_vm.navigationConfirmationModal = false),
                        actions: _vm.navigationConfirmationActions,
                      },
                    },
                    [
                      _vm._v(
                        "You have unsaved changes, would you like to save these changes?"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.deleteConfirmationModal
            ? _c(
                "EditingModal",
                { attrs: { size: "sm" } },
                [
                  _c(
                    "AlertDialog",
                    {
                      attrs: {
                        title: "Confirm Delete",
                        onExit: () => (_vm.deleteConfirmationModal = false),
                        actions: _vm.deleteConfirmationActions,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(
                          `Are you sure you want to delete ${_vm.groupName}?`
                        )
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }